import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'

import anime from 'animejs'

//import { FaGithub, FaLinkedin } from 'react-icons/fa'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center'
  },
  paper: {
    marginRight: '10px',
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  borderShape: {
    position: 'absolute',
    width: '4px',
    height: '4px',
    margin: '4px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main
  }
}));

const BorderShape = (props) => {
  const classes = useStyles()

  return (<div {...props} id='special' className={classes.borderShape} />)
}

export default function Home() {
  const classes = useStyles()

  let borderShapes = []
  for (let i = 0; borderShapes.length < 8; ++i) {
    borderShapes.push(<BorderShape key={i} />);
  }

  const nameRef = React.useRef(null)

  const animationRef = React.useRef(null)
  React.useEffect(() => {
    animationRef.current = anime({
      targets: '#special',
      keyframes: [
        {translateX: nameRef.current.offsetWidth},
        {translateY: -(nameRef.current.offsetHeight + 12)},
        {translateX: -12},
        {translateY: 0}
      ],
      direction: 'alternate',
      loop: true,
      easing: 'easeOutElastic(1, .9)',
      duration: 4000,
      delay: anime.stagger(300) // increase delay by 100ms for each elements.
    });
  }, [])

  return (
    <Container className={classes.container}>
      <div className={classes.root}>
        <Grid container spacing={3} justify='center'>
          <Grid item xs={6} align='center'>
            <Paper ref={nameRef} className={classes.paper}>
              <Typography variant='h3'>Nicholas Wong</Typography>
            </Paper>
            {borderShapes.map(el => {
              return el
            })}
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

// export const query = graphql`
// query ProjectsShortDescQuery {
//   allMarkdownRemark(sort: {fields: frontmatter___endDate, order: DESC}) {
//     nodes {
//       frontmatter {
//         title
//         shortDescription
//         company
//       }
//       fileAbsolutePath
//     }
//   }
// }`